define("discourse/plugins/discourse-antivirus/discourse/initializers/admin-plugin-configuration-nav", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-antivirus-admin-plugin-configuration-nav",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if (!currentUser || !currentUser.admin) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.1.0", api => {
        api.addAdminPluginConfigurationNav("discourse-antivirus", [{
          label: "antivirus.stats.title",
          route: "adminPlugins.show.discourse-antivirus-stats"
        }]);
      });
    }
  };
});